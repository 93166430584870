import { Caption2SemiBold } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

// FooterLinks keys must match the keys in en.json to display the link label
import { FooterLinks } from '~/constants'
import { EventClick, EventScreenName } from '~/events/eventTypes'
import useTrackClick from '~/events/trackers/useTrackClick'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

type FooterLinkKeys = keyof typeof FooterLinks
type FooterLinkCodes = typeof FooterLinks[FooterLinkKeys]['code']

interface FooterProps {
  className?: string
  // showLinks will show the links with the keys provided
  // if not provided, all links will be shown
  showLinks?: Array<FooterLinkCodes>
}

const Footer: React.FC<FooterProps> = ({ className, showLinks }) => {
  const t = useTranslationForNamespace('common')
  const year = new Date().getFullYear()
  const { trackCta } = useTrackClick()

  const footerLinksMap = {
    termsOfService: {
      ...FooterLinks['termsOfService'],
      clickName: EventClick.names.TermsOfService,
      destination: EventScreenName.TermsOfService,
      label: t(`footer.termsOfService`),
    },
    privacyPolicy: {
      ...FooterLinks['privacyPolicy'],
      clickName: EventClick.names.PrivacyPolicy,
      destination: EventScreenName.PrivacyPolicy,
      label: t(`footer.privacyPolicy`),
    },
    californiaPrivacy: {
      ...FooterLinks['californiaPrivacy'],
      clickName: EventClick.names.CaliforniaPrivacy,
      destination: EventScreenName.CaliforniaPrivacy,
      label: t(`footer.californiaPrivacy`),
    },
    help: {
      ...FooterLinks['help'],
      clickName: EventClick.names.VisitHelpdesk,
      destination: EventScreenName.HelpCenter,
      label: t(`footer.help`),
    },
  } as const

  const listOfFooterLinks = Object.values(footerLinksMap)
  const filteredLinks = showLinks
    ? listOfFooterLinks.filter((item) => showLinks.includes(item.code))
    : listOfFooterLinks

  const handleClick = (item: typeof filteredLinks[number]) => {
    trackCta({
      click_name: item.clickName,
      destination: item.destination,
      text: item.label,
      type: EventClick.types.Link,
    })
  }

  return (
    <FooterContainer className={className}>
      <Copyright>© {year} LUMOS LABS, INC.</Copyright>
      <Links>
        {filteredLinks.map((item) => (
          <LinkContainer key={item.code} onClick={() => handleClick(item)}>
            {/* use a instead of next/link as we don't want to prefetch on hover */}
            <a href={item.url}>
              <LinkText>{item.label}</LinkText>
            </a>
          </LinkContainer>
        ))}
      </Links>
    </FooterContainer>
  )
}

const Copyright = styled(Caption2SemiBold)`
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: block;
    padding: 15px;
  }
  color: ${({ theme }) => theme.colorTokens.text['text-subdued']};
  padding-top: 5px;
  text-transform: uppercase;
`

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 40px;
  color: ${({ theme }) => theme.colorTokens.text['text-subdued']};
  @media (max-width: 660px) {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
    align-items: center;
  }
`

const LinkContainer = styled.span`
  padding: 0 16px;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: block;
    text-align: center;
  }
`

const Links = styled.div`
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
`

const LinkText = styled(Caption2SemiBold)`
  color: ${({ theme }) => theme.colorTokens.text['text-subdued']};
`

export default Footer
