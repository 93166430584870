import styled from 'styled-components'

export interface PageContentProps {
  children: React.ReactNode
}

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr; /* Header takes auto height, main and aside take remaining space */
  grid-template-columns: 1fr auto; /* Main content takes available space, aside takes its content width */
  gap: 24px;
  padding: 40px 60px;
  color: ${({ theme }) => theme.colors.neutral[400]};
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    padding: 24px 24px;
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.desktopSmall} {
    grid-template-columns: 1fr;
  }
`

const PageContent = ({ children }: PageContentProps): JSX.Element => <Container>{children}</Container>

PageContent.Header = styled.header`
  grid-column: 1 / -1;
`

PageContent.Main = styled.main``

PageContent.Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mediaQuery.maxWidth.desktopSmall} {
    display: none;
  }
`

export default PageContent
